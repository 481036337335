import React from 'react'

export default function articles() {
    return (
        <svg width={478 * '1em'} height={391 * '1em'} viewBox="0 0 478 391" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M310.656 3.91781L113.575 125.742L111.472 122.34L308.552 0.515381L310.656 3.91781Z" fill="#028F99"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M473.53 272.133L350.974 95.5653L186.718 209.576L309.274 386.143L473.53 272.133ZM351.727 91.3902L182.542 208.822L308.52 390.318L477.705 272.887L351.727 91.3902Z" fill="#EC4A27"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M263.046 295.819C332.52 241.132 344.507 140.479 289.82 71.0059C235.132 1.53232 134.48 -10.4545 65.0068 44.2326C-4.4668 98.9196 -16.4536 199.572 38.2334 269.045C92.9205 338.519 193.573 350.506 263.046 295.819ZM264.902 298.176C335.677 242.464 347.889 139.926 292.177 69.1503C236.465 -1.62516 133.927 -13.8366 63.1512 41.8753C-7.62428 97.5871 -19.8357 200.125 35.8761 270.901C91.588 341.676 194.126 353.888 264.902 298.176Z" fill="#EC4A27"/>
        </svg>

    )
}

